.rise-up {
    position: relative;
    animation-name: riseup;
    animation-duration: 1s;
  }

  @keyframes riseup { 
    from{ 
      bottom: -100px; 
      opacity: 0;
    } 
    to{ 
      bottom: 0; 
      opacity: 1;
    }
  }