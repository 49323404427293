$huge: 3rem;
$xlarge: 2.2rem;
$large: 1.6rem;
$medium: 1.3rem;
$normal: 1rem;
$small: .9rem;
$smaller: .8rem;
$smallest: .7rem;


