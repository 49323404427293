@use 'abstracts/colors';
@use 'layout/structure';
@use 'base/typography';

.gallery {
    display: flex;
    flex-wrap: wrap;
    // padding: structure.$lean; 
    figure {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
        height: 369px;
        overflow: hidden;
        margin: structure.$fat;
        // border: solid structure.$lean colors.$white;
        a {
            // position: absolute;
            z-index: 1000;
            background: transparent;
            width: fit-content;
            height: 100%;
        }
        img {
            // max-width:100%;
            max-height:100%;  
            transition: all .15s;
            &:hover {
                // transform: scale(1);
                cursor: pointer;
            }
        }
    }
}

.gallery > * {
    flex: 1 1 25%;
    @media only screen and (max-width: 1000px)  {
        flex: 1 1 33%;
    }
    @media only screen and (max-width: 600px)  {
        flex: 1 1 50%;
    }
    @media only screen and (max-width: 400px)  {
        flex: 1 1 100%;
    }
}

.portfolio {
    span {
        display: grid;
        align-content: flex-end;
        height: 100%;
        color: rgba($color: colors.$black, $alpha: 1);
        background: rgba($color: colors.$white, $alpha: .4);
        transition: all .5s;
        padding: 0px;
        h5 {
            // text-shadow: 1px 1px 10px rgba($color: black, $alpha: .0);
            // text-transform: uppercase;
            // padding-bottom: structure.$lean;
            font-size: typography.$smaller;
            font-weight: 400;
            line-height: 1rem;
            text-shadow: 1px 1px 10px rgba($color: black, $alpha: .5);

        }
    }
    figure {
        height: 300px;
        &:hover span {
            color: rgba($color: white, $alpha: 1);
            background: rgba($color: colors.$white, $alpha: .1);
            transition: all .5s;
            // h4 {
            // }
        }
    }
}

.portfolio > * {
    flex: 1 1 15%;
    @media only screen and (max-width: 1000px)  {
        flex: 1 1 25%;
    }
    @media only screen and (max-width: 600px)  {
        flex: 1 1 33%;
    }
    @media only screen and (max-width: 400px)  {
        flex: 1 1 100%;
    }
}

.aspect {
    width: 100%;
    padding: 0px;
    figure {
        height: auto;
    }
    img {
        width: 100%;
    }
}

.screenshot {
    figure {
        background-color: colors.$lightest;
        img, video {
            padding: 0;
            box-shadow: 0 1px 23px hsla(0, 0%, 0%, .3);
            max-height:93%;  
            max-width: 93%;
            // transition: all .15s;
            &:hover {
                transform: scale(1);
                cursor: default;
            }
        }
    }
    
}

.micro {
    figure {
        background-color: inherit;
        height: 400px;
        @media only screen and (max-width: 400px)  {
            height: 300px;
        }
        img, video {
            padding: 0;
            box-shadow: 0 1px 23px hsla(0, 0%, 0%, .3);
            height: 300px;  
            @media only screen and (max-width: 400px)  {
                height: 200px;
            }
            // max-width: 93%;
            // transition: all .15s;
            &:hover {
                transform: scale(1);
                cursor: default;
            }
        }
    }
    
}

.ui-video {
    figure {
        height: auto;
        background-color: colors.$lightest;
        img, video {
            padding: 0;
            box-shadow: 0 1px 23px hsla(0, 0%, 0%, .3);
            // max-height:93%;  
            // max-width: 93%;
            // transition: all .15s;
            &:hover {
                transform: scale(1);
                cursor: default;
            }
        }
    }
    
}