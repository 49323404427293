@use 'abstracts/colors';

//begin  experimental loader ***
// TO-DO::: make animations wait for the loader :::
// https://css-tricks.com/making-animations-wait/
#loader {
    position: fixed;
    z-index: 3;
    background-color: colors.$white;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 0;
}

#spinner {
    height: 72px;
    width: 72px;
    margin: 42vh auto;
    background-color: transparent;
    border: 2px solid colors.$lightest;
    border-top: 1px solid colors.$darkest;
    border-radius: 50% 50% 50% 50%;
    -webkit-animation: spin .72s linear infinite;
    animation: spin .72s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

#app:empty + #loader { display: block; }
// end experimental loader