@use './typeface';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 100%;
    text-decoration: none;
    list-style: none;
    font-family: typeface.$font;
}