// $black: #333333;
// $darkest: #555555;
// $darker: #777777;
// $dark: #999999;
// $light: #cccccc;
// $lighter: #eaeaea;
// $lightest: #f1f1f1;
// $white: #ffffff;

$black: #fafafa;
$darkest: #eaeaea;
$darker: #cccccc;
$dark: #999999;
$light: #777777;
$lighter: #555555;
$lightest: #333333;
$white: #222222;