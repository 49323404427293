@use 'abstracts/colors';
@use 'layout/structure';

article {
    
    padding: structure.$lean;
    // padding-bottom: structure.$lean;
    // background: colors.$lightest;
    // border: solid 1px colors.$white;
    // margin: structure.$lean;
    color: colors.$dark;
    flex: 1;
    h3 {
        padding: 0;
    }
}