@use '../abstracts/colors';
@use '../layout/structure';
@use './typeface';
@use './typography';

body {
    background: colors.$white;
    font-family: typeface.$font;
    color: colors.$darker;
    line-height: 1.5rem;
    letter-spacing: .072rem;
    @media only screen and (min-width: 1500px) {
        background-color: black;
        }
}

#app {
    position: relative;
    @media only screen and (min-width: 1500px) {
        width: 1500px;
        margin: 0 auto;
        }
}

a {
    font-weight: 200;
    color: colors.$darker;
    &:hover {
        color: colors.$black;
    }
}

p {
    font-weight: 200;
    line-height: 1.8rem;
    font-size: typography.$normal;
    @media only screen and (max-width: 800px) {
        line-height: 1.5rem;
        font-size: typography.$small;
        }
    @media only screen and (max-height: 400px) {
        line-height: 1.3rem;
        font-size: typography.$smaller;
        }
}
h1 {
    font-weight: 100;
    line-height: 3.3rem;
    font-size: typography.$huge;
    padding-bottom: structure.$lean;
    @media only screen and (max-width: 800px) {
        font-size: typography.$xlarge;
        line-height: 2.6rem;
        }
    @media only screen and (max-height: 400px) {
        font-size: typography.$large;
        line-height: 2.3rem;
        }
}

h2 {
    font-weight: 200;
    line-height: 2.3rem;
    font-size: typography.$xlarge;
    @media only screen and (max-width: 800px) {
        line-height: 1.8rem;
        font-size: typography.$large;
        }
    @media only screen and (max-height: 400px) {
        font-size: typography.$large;
        line-height: 2.3rem;
        }
    padding-bottom: structure.$lean;
}

h5, h6 {
    text-transform: uppercase;
    padding-bottom: structure.$lean;
    font-size: typography.$smaller;
    font-weight: 200;
    line-height: 1rem;
}

h6 {
    font-weight: bold;
    opacity: .2;
}

.center-copy {
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 600px) {
        h2, h3, h4, h5, p{
            align-self: center;
            max-width: 72%;
            text-align: center;
        }
    }
}


select {
    background-color: transparent;
    border: 0px;
    color: colors.$black;
    letter-spacing: .072rem;
}

.cta {
    display: flex;
    width: fit-content;
    padding: structure.$lean;
    border: 1px solid colors.$darkest;
    color: colors.$darkest;
    font-size: typography.$normal;
    margin-top: structure.$fat;
    text-decoration: none;
    svg {
        align-self: center;
        height: typography.$normal;
        path {
            fill: colors.$darkest;
        }
    }
    &:hover {
        color: colors.$black;
        border: 1px solid colors.$black;
        // background: colors.$white;
        svg {
            path {
                fill: colors.$black;
            }
        }
    }
}

.icon-left {
    padding-right: structure.$thin;
}

.icon-right {
    padding-left: structure.$thin;
}


// iconography
.play-video {
    // cursor: url('https://i.imgur.com/lqqXQoU.gif'), auto;	
    cursor: text;
}

// .dark {
//     border: 1px solid colors.$lightest;
//     color: colors.$lightest;
//     &:hover {
//         color: colors.$white;
//         border: 1px solid colors.$white;
//         background: colors.$black;
//     }
// }

#app {
    @media only screen and (max-width: 600px)  {
        margin-top: 46px; // makes room for nav
    }
}