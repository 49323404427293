@use 'abstracts/colors';
@use 'layout/structure';
@use 'base/typography';

footer {
    // font-size: typography.$small;
    
    line-height: 1.2rem;
    padding: structure.$fat;
    display: flex;
    @media only screen and (max-width: 800px)  {
        display: block;
    }
    width: 100%;
    background: colors.$lighter;
    h2 {
        flex: 1;
        text-transform: uppercase;
        font-size: typography.$medium;
        // padding: structure.$lean;
        color: colors.$darkest;
        a {
            font-weight: 400;
        }
        @media only screen and (max-width: 600px)  {
            padding-bottom: 0;
        }
    }
    a {
        display: block;
        color: colors.$darker;
        padding: structure.$ts structure.$lean structure.$ts structure.$lean;
    }
    h3 {
        padding: structure.$lean;
        font-size: typography.$medium;
        font-weight: 200;
        color: colors.$darkest;
        max-width: 50%;
    }
    nav {
        ol {
            position: relative;
            z-index: 500;
            li {
                // padding: structure.$lean;
                padding-top: structure.$crop;
                // @media only screen and (max-width: 600px)  {
                //     padding-top: structure.$crop;
                // }
            }
        }
        flex: 1 40%;
        @media only screen and (max-width: 600px)  {
            display: block;
            padding-top: structure.$ts;
        }
        ol.panel {
            display: block;
        }
    }
    
}

.footer-base {
    width: 100%;
    display: block;
    color: colors.$darker;
    text-align: center;
    font-weight: 200;
    background: colors.$lighter;
    font-size: typography.$smaller;
    padding: structure.$fat;
}