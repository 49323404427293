@use 'abstracts/colors';
@use 'layout/structure';
@use 'base/typography';

.caption {
    position: absolute;
    z-index: 9;
    color: colors.$dark;
    bottom: 0;
    left: 0;
    font-size: 1.2rem;
    background: transparent;
    // background: rgba($color: colors.$white, $alpha: .5);
    // backdrop-filter: blur(5px);
    margin: 0;
    font-size: typography.$small;
	text-align: center;
    padding: structure.$lean;
    width: 100%;
}

.full-caption {
    // background-color: rgba($color: #000000, $alpha: .5);
    // backdrop-filter: blur(5px);
    padding: structure.$fat;
}

.fullscreen {
	display: none; /** hide by default */
	position: fixed;
	z-index: 999;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
    background: rgba($color: colors.$white, $alpha: .8);
    backdrop-filter: blur(33px);

}
.fullscreen::before {
    content: "✕";
    position: absolute;
    top: 0;
    right: 0;
    padding: structure.$fat;
    padding-top: structure.$lean;
    font-size: 1rem;
}
.fullscreen img, video {
    overflow: scroll;
    align-self: center;
    justify-self: center;
    max-width: 100%;
    max-height: 100vh; // zoom and scale :D
    object-fit: cover; // helps prevent stretching
    outline: none;
    padding: structure.$double;
    @media only screen and (max-width: 800px) {
        padding: structure.$ts;
    }   
    // padding-top: 52px;
    // max-width: 90%;
    // max-height: 70%;
    // width: 90%;
    // height: auto;
}
// @media only screen and (min-width: 1000px) {
//     .fullscreen img {
//         height: 80%;
//         width: auto;
//     }
// }

.fullscreen span {
    align-self: flex-end;
    justify-self: center;
    width: 100%;
}

.fullscreen:target {
	outline: none;
	display: grid;
}