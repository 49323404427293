@use 'abstracts/colors';
@use 'layout/structure';
@use 'base/typography';

$navcolor: colors.$lighter;

header {
    display: flex;
    font-size: typography.$small;
    line-height: 1rem;
    position: fixed;
    top: 0;
    z-index: 900;
    width: 100%;
    @media only screen and (min-width: 1500px) {
        width: 1500px;
        margin: 0 auto;
        }
    background: $navcolor;
    // box-shadow:  0 13px 13px hsla(0, 0%, 0%, .1);
    @media only screen and (max-width: 600px) {
        height: 46px;
    }
    h2 {
        padding: structure.$lean;
        width: 100%;
        cursor: pointer;
        font-size: typography.$smaller;
        line-height: 1rem;
        text-transform: uppercase;
        a {
            color: colors.$darkest;
            font-weight: 400;
            &:hover {
                color: colors.$black;
            }
        }
        @media only screen and (max-width: 600px) {
            position: absolute;
            z-index: 900;
            width: auto;
        }
    }

header > * {
    flex-basis: 50%;
}
//responsive menu open close button
    .menu-button {
        display: none;
        padding: 22px;
        background: transparent;
        // padding: structure.$lean;
        border: 0;
        position: absolute;
        cursor: pointer;
        right: 0;
        top: 0;
        outline: none;
        @media only screen and (max-width: 600px) {
            display: block;
            z-index: 100; 
        }
    }
    nav {
        width: 100%;
        // flex: 1 40%;
        // padding: structure.$lean;
        a {
            display: block;
            color: colors.$darkest;
            padding: structure.$ts structure.$lean structure.$ts structure.$lean;
            &:hover {
                color: colors.$black;
            }
        }
        ol {
            display: block;
            background: colors.$lighter;
            padding-bottom: structure.$ts;
            // padding-top: structure.$lean;
            li {
                // padding-top: structure.$crop;
                &:hover {
                    background: colors.$lightest;
                }
            }

        }
        h3 {
            color: colors.$darkest;
            padding: structure.$lean;
            text-transform: uppercase;
            font-size: typography.$smaller;
            font-weight: 200;
            a {
                color: colors.$darkest;
                font-weight: 200;
                &:hover {
                    color: colors.$black;
                }
            }
        }
//responsive menu overlay
        @media only screen and (max-width: 600px)  { 
            display: none;
            background-color: $navcolor;
            width: 100vw;
            height: 100vh;
            position: absolute;
            z-index: 1; 
            padding-top: structure.$double;
            h3 {
                font-size: typography.$medium;
                font-weight: 200;
                cursor: pointer;
            }
            ol {
                display: none;
                li {
                    display: block;
                }
    
            }
        }
    }
}


// begin hamburger icon animation
.hamburglar {
    display: block;
    position: relative;
}

.hamburglar,
.hamburglar::before,
.hamburglar::after {
    width: 1rem;
    height: 2px;
    background: colors.$darkest;
    transition: transform .1s ease-in-out, opacity .1s linear;
}

.hamburglar::before,
.hamburglar::after {
    content: '';  
    position: absolute;
    left: 0;
}

.hamburglar::before {
    transform: translateY(-5px);
}
.hamburglar::after {
    transform: translateY(5px);
}

.on .hamburglar {
    transform: rotate(45deg);
}

.on .hamburglar::before {
    opacity: 0;
}

.on .hamburglar::after {
    transform: translateY(0px);
    transform: rotate(90deg);
}
