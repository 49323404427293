@use 'abstracts/colors';
@use 'layout/structure';
@use 'base/typography';

nav {
    // padding: structure.$lean;
    display: flex;

    ul {
        position: relative;
        list-style: none;
        // flex: 1;
        // border-left: 2px solid colors.$white;
        // margin-left: 2px;
    }
    ol {
        display: block;
        position: absolute;
        width: 100%;
        z-index: 1000;
        @media only screen and (max-width: 600px) {
            position: relative;
        }
        a {
            font-size: typography.$smaller;
            &:hover {
                color: colors.$black;
            }
        }

    }
    a {
        // flex: 1;
        &:hover {
        color: colors.$darkest;
        }
    }
    h3 {
        white-space: nowrap;
        color: colors.$dark;
        font-weight: normal;
        cursor: pointer;
        &:hover {
            color: colors.$black;
        }
    }
    // .active {
    //     background: colors.$lightest;
    // }
}

nav > * {
    flex-basis: 100%;
}

.active {
    // background: red;
    position: relative;
}


// pseudo element to close menu when clicked outside of
.active::before {
    content: "";
    display: block;
    // unnecessary with mobile nav, so hide it!
    @media only screen and (max-width: 600px) {
        display: none;
    }
    position: absolute;
    z-index: 800;
    top: 46px;
    left: -100vw;
    width: 200vw;
    height: 100vh;
    background: transparent;
}