$double: 60px;
$fat: 30px;
$lean: 15px;
$thin: 10px;
$ts: 5px;
$crop: 0px;

.collapse {
    padding-left: 0px;
    padding-right: 0px;
    h2, h3, h4, h5, p {
        padding-left: $fat;
        padding-right: $fat;
    }
}

.hide {
    display: none;
}

// class for fx/accordion.js
.panel {
    display: none;
}

.show {
    display: block;
    @media only screen and (min-width: 600px) {
        display: inherit;
    }
}

// page sizes
.full {
    height: 100vh;
    @media only screen and (max-height: 400px) {
        height: fit-content;
    }
}
.full-adapt {
    height: 100vh;
    @media only screen and (max-width: 600px) {
        height: fit-content;
    }
}
.ninety {
    height: 90vh;
    max-height: 900px;
    @media only screen and (max-width: 400px) {
        // height: fit-content;
        height: 150vh;
    }
    @media only screen and (max-height: 400px) {
        height: fit-content;
    }
}
.eighty {
    height: 80vh;
}
.seventy {
    height: 70vh;
}


.three-quarter {
    height: 75vh;
}
.half {
    height: 50vh;
}
.two-third {
    height: 66vh;
}
.one-third {
    height: 33vh;
}
.one-quarter {
    height: 25vh;
}



// flexbox column layouts
//MAKES ALL COLS EVEN REGARDLESS OF CONTENT
.even-columns {
    display: flex;
}

.even-columns > * {
    flex-basis: 100%;
}

//SEMI MASONRY TYPE LAYOUT
.grid-like {
    display: flex;
    flex-wrap: wrap;
}

.grid-ish > * {
    flex: 1 1 33%;
}