@use 'abstracts/colors';
@use 'layout/structure';
@use 'base/typography';


section {
    background: colors.$lightest;
    padding: structure.$fat;
    // padding-bottom: structure.$crop;
    a {
        // color: colors.$light;
        text-decoration: underline;
        &:hover {
            color: colors.$dark;
        }
    }
    p {
        padding-bottom: structure.$lean;
    }

    h3, h4 {
        font-weight: 200;
        line-height: 2rem;
        font-size: typography.$large;
        @media only screen and (max-width: 600px) {
            line-height: 1.2rem;
            font-size: typography.$medium;
            }
        padding-bottom: structure.$lean;
    }
}

section:nth-child(2n) {
    background: colors.$white;
}

.hero {
    display: flex;
    padding: 0; // offset for nav
    flex-direction: column;
    justify-content: center;
    color: colors.$black;
    background-color: colors.$lightest;
    background-size: cover;
    background-position: center center;
    // h1 {
    //     font-size: typography.$xlarge;
    //     line-height: 2.2rem;
    //     max-width: 50%;
    //     @media only screen and (max-width: 600px)  {
    //         max-width: 100%;
    //     }
    // }
    // h2 {
    //     font-size: typography.$large;
    // }
    // p {
    //     font-size: typography.$medium;
    //     }
    // }
}


.flexit {
    // background-color: colors.$lightest;
    display: flex;
    overflow: hidden;
    @media only screen and (max-width: 600px)  {
        display: block;
    }
}

.flexit > * {
    flex-basis: 100%;
}



.half-pg {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 600px)  {
        flex-direction: column-reverse; 
        align-items: flex-start;
    }
    div {
        padding: structure.$fat;
    }
    figure {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 100%;
        height: 100%;
        img {
            // max-width: 100%;
            max-height: 100vh; // zoom and scale :D
            object-fit: cover; // helps prevent stretching
        }
    }
}

.half-pg > * {
    flex-basis: 50%;
}

// change order of half-pg elements
.flip {
    @media only screen and (max-width: 600px)  {
        flex-direction: column; 
        align-items: flex-start;
    }
}

.white {
    background: colors.$white;
}

.divider {
    border-top: solid 1px colors.$lighter;
}

.crop-top {
    padding-top: 0px;
}

.crop-bottom {
    padding-bottom: 0px;
}